<template>

  <b-modal
      centered
      :ok-disabled="true"
      id="edit-order"
      :no-close-on-backdrop="true"
      :static="true"
      @show="loadOrderInfo"
      @close="cancelOrderX"

  >
    <template v-slot:modal-title><code><h1>{{userCode(parseInt(order.user_id))}}</h1> {{order.make}} {{order.part_number}} ({{order.status}})</code></template>
    <template v-slot:modal-footer>
      <b-button variant="warning" size="sm" @click="cancelOrder">Отмена</b-button>
      <b-button variant="success" size="sm" @click="saveOrder" v-if="showSave">Сохранить</b-button>
    </template>
    <div class="d-block">
      <table class="table table-sm table-borderless table-responsive-sm">
        <tbody v-if="parseInt(this.order.self_order) === 0">
        <tr><td colspan="2"><a :href="`https://www.google.com/search?q=${order.make}%20${order.part_number}&tbm=isch`" target="_blank">{{order.make}}</a></td></tr>
        <tr><td colspan="2">{{order.item_desc}}</td></tr>
        <tr><td colspan="2">{{order.barcodes}}</td></tr>
        <tr><td colspan="2">{{order.notes}}</td></tr>
        <tr v-if="order.tracking"><td>Трек кномер</td><td>{{order.tracking}}</td></tr>
        <tr><td colspan="2">{{order.store}}</td></tr>
        <tr><td>Количество: <br/>{{order.recv}}/{{order.qty}}</td>
          <td>
            <b-input-group class="mt-0">
              <b-input-group-prepend>
                <b-button variant="warning" @click="addRemainingQty()">{{remainingQty}}</b-button>
              </b-input-group-prepend>
              <b-form-input type="text" class="form-control" @keyup="handleKeyPress($event, 'recv')" ref="input_qty" v-model="recv"></b-form-input>
                <NumbersModal :numberString.sync="recv" :maxqty="remainingQty.toString()" :input_name="`inputQty`" :mkey="`recv`"></NumbersModal>
            </b-input-group>
          </td>
        </tr>
        <tr>
          <td :class="weightClass">Вес (<span :class="{'text_green': order.weight_source === 'confirmed', 'text_red': order.weight_source !== 'confirmed'}">{{order.weight}}</span>)</td>
          <td>
            <b-input-group class="mt-0">

              <b-form-input type="text" class="form-control" @keyup="handleKeyPress($event, 'weight')" ref="input_weight" v-model="weight"></b-form-input>
                <NumbersModal :numberString.sync="weight" :input_name="`inputWeight`" :mkey="`weight`"></NumbersModal>
            </b-input-group>
          </td>
        </tr>
        <tr>
          <td :class="barcodeClass">Штрихкод</td>
          <td>
            <b-input-group class="mt-0">
              <b-form-input type="text" class="form-control"  @keyup="handleKeyPress($event, 'barcode')" ref="input_barcode" v-model="barcode_full"></b-form-input>
            </b-input-group>
          </td>
        </tr>
        <tr v-show="order.status === 'inmoscow'"><td colspan="2"><b-form-checkbox v-model="toWaiting">В ожидание</b-form-checkbox></td></tr>
        <tr v-show="order.status === 'tomoscow' & order.recv_history.length > 0 "><td colspan="2"><b-form-checkbox v-model="cancelIncome">Отмена приемки</b-form-checkbox></td></tr>
        </tbody>
        <tbody v-else>
        <tr><td colspan="2">{{order.client}}</td></tr>
        <tr><td colspan="2">{{order.tracking}}</td></tr>
        <tr><td>Название</td><td>{{order.item_desc}}</td></tr>
        <tr><td>Комментарий</td><td>{{order.notes}}</td></tr>
        <tr><td>Отметить все</td><td><b-form-checkbox v-model="checkAllSameTrack"></b-form-checkbox></td></tr>
        <tr v-show="order.status === 'inmoscow'"><td>В ожидание</td><td><b-form-checkbox v-model="toWaiting"></b-form-checkbox></td></tr>
        <tr><td>Удалить</td><td><b-form-checkbox v-model="deleteTrack"></b-form-checkbox></td></tr>
        </tbody>
      </table>
      <table class="table table-bordered table-sm">
        <thead>
        <tr>
          <th>Дата (мск)</th>
          <th>Qty</th>
          <th>Usr</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="recv_history in order.recv_history" :key="recv_history.key">
          <td nowrap="">{{recv_history.updated_at}}</td>
          <td>{{recv_history.qty}}</td>
          <td>{{getManagerID(recv_history.updated_by)}}</td>
        </tr>
        </tbody>
      </table>
    </div>

  </b-modal>
</template>

<script>
import { mapMutations } from 'vuex';
import mixins from "@/services/mixins";
import Http from "@/services/Http";
import eventBus from "@/services/eventBus";
import NumbersModal from "@/components/NumbersModal.vue";
export default {
  components: {NumbersModal},
  strict: true,
  data: () => ({
    order: {},
    weight: null,
    recv: null,
    barcode: null,
    deleteTrack: false,
    checkAllSameTrack: false,
    toWaiting: false,
    cancelIncome: false,
    barcode_full: null,
    touch_timer: null,
  }),
  name: 'EditOrderModals',
  mixins: [mixins],
  computed: {
    showSave() {
      if (this.cancelIncome || this.toWaiting || this.currentUserID === 528 || this.currentUserID === 527 || this.currentUserID === 526 || parseInt(this.order.self_order) === 1) {
        return true;
      }
      return false;

    },
    currentUserID() {
      let user = this.currentUser()
      return parseInt(user["id"])
    },
    remainingQty() {
      return this.order.qty-this.order.recv
    },
    weightClass() {
      return {
        'table-danger': this.order.noweight === 1 || this.order.weight_source !== 'confirmed',
      }
    },
    barcodeClass() {
      return {
        'table-danger': this.order.barcodes.length === 0,
      }
    }
  },
  methods: {
    ...mapMutations(["setOrderParam"]),
    getManagerID(user_id) {
      return this.$store.getters.getManagerIDS[user_id];
    },
    handleKeyPress(event, inputName) {
      if (event.key === 'Enter') {
        this[inputName] = this.checkCommandInValue(event.target.value, inputName);
        this.setNextFocus();
      }
    },
    setNextFocus: function () {
      setTimeout(() => {
        let setFocus = this.$refs.input_barcode;


        if(this.weight === null || this.weight === ''){
          setFocus = this.$refs.input_weight
        }

        if(this.recv === null || this.recv === ''){
          setFocus = this.$refs.input_qty
        }

        setFocus.focus()
      }, 200);

    },

    checkCommandInValue: function (val, type){
      console.log(val)

      // Если тип recv , то проверяем ввод, если в поле ввода веден штрихкод запчасти (val in barcodes)
      // то штрихкод заменяем максимальным количеством которое ожидаем
      if(type === 'recv'){
        if(this.order.barcodes.includes(val)){
          return this.remainingQty
        }
      }

      // if val undef or empty return null

      if(typeof val === 'string' && val.endsWith('!') && val.includes(',')) {
        let parts = val.split(',')
        if (parts.length === 3) {

          // проверяем что возмжно это вес
          if(parts[1] === "0000"){
            this.weight = parts[2].replace('!', '')
            if(type !== 'weight'){
              let audio = new Audio(require('@/assets/sound/material_ok.wav'));
              audio.play();
              this.setNextFocus();
              return parts[0];
            }
            let audio = new Audio(require('@/assets/sound/material_ok.wav'));
            audio.play();
            this.setNextFocus();
            return this.weight;
          }

          if(type === 'recv'){
            this.recv = parts[0]
          }

          // let input_qty = parts[0]
          let user_id = parts[2].replace('!', '')
          console.log(user_id)
          console.log(this.order.user_id)

          // Если проверка прошла в поле штрихкода, очищаем его от лишних управляющих символов
          if(type === 'barcode'){
            this.barcode_full = parts[0]
          }

          if (parseInt(user_id) === parseInt(this.order.user_id)) {
            // this.recv = input_qty
            this.saveOrder()
          } else {
            let audio = new Audio(require('@/assets/sound/material_alert.wav'));
            audio.play();
            this.$bvToast.toast('Не тот клиент', {
              title: 'Ошибка',
              variant: 'danger',
              solid: true,
              toaster: 'b-toaster-top-center'
            })
            // this.recv = input_qty
          }
        }
      }
      return val
    },
    addRemainingQty: function (){
      this.recv = this.remainingQty
    //   change input focus to weight field
      this.setNextFocus()
    },
    cancelOrder: function () {
      this.$store.commit('setOrder', {'order': null});
      this.$bvModal.hide('edit-order')
      eventBus.$emit('clean-text-input')
    },
    cancelOrderX: function () {
      this.$bvModal.hide('edit-order')
      eventBus.$emit('clean-text-input')
    },
    focusInputWeight: function () {
      if(parseInt(this.order.self_order) === 0){
        if(parseInt(this.order.qty) === 1){
          setTimeout(() => {
            this.$refs.input_weight.focus()
          }, 300)
        }else{
          setTimeout(() => {
            this.$refs.input_qty.focus()
          }, 100)
        }
      }
    },
    loadOrderInfo: function () {
      this.changed_fields = {}
      this.deleteTrack = false
      this.checkAllSameTrack = false
      this.toWaiting = false
      this.cancelIncome = false
      this.weight =  null
      this.recv =  null
      this.barcode_full = null
      this.order = JSON.parse(JSON.stringify(this.$store.getters.getOrder))
      if(parseInt(this.order.qty) === 1){
        this.recv = 1;
      }
      this.focusInputWeight()
      setTimeout(() => {
        Http.touchOrder(this.order.item_id);
      }, 1000)
    },
    userCode: function(user_id) {
      let user = this.$store.getters.getUsers.find(user => user.id === user_id);
      if (user) {
        return user.code;
      }
      return "No Code";
    },
    saveOrder: function () {
      let status = 'inmoscow'
      if(this.toWaiting){
        // Добавляем статус tomoscow для возврата запчасти назад в ожидание
        status = "tomoscow"
      }

      if(parseInt(this.order.self_order) === 0){
        this.doSaveParts(status)
      }else if (parseInt(this.order.self_order) === 1){
        this.doSaveTracks(status)
      }
      eventBus.$emit('clean-text-input')
    },
    doSaveTracks: function (status){
      let set_manual_weight = 0

      if(this.deleteTrack){
        status = "remove"
      }

      Http.doPartsReceived({
        "item_id": parseInt(this.order.item_id),
        "set_manual_weight": set_manual_weight,
        "checkAllSameTrack": this.checkAllSameTrack,
        "status": status,
        // item_id=176422&set_current_weight=0&set_manual_weight=500
      }).then((response) => {
          if(this.deleteTrack) {
              this.$bvToast.toast('Трек удален', {
                  title: 'Успешно',
                  variant: 'success',
                  solid: true,
                  toaster: 'b-toaster-top-center'
              })
              this.deleteOrderByItemId(response.data.item_id)
              let audio = new Audio(require('@/assets/sound/material_ok.wav'));
              audio.play();
              this.$bvModal.hide('edit-order')
              eventBus.$emit('clean-text-input')
              return
          }
          if(this.checkAllSameTrack){
              response.data.items.forEach((item) => {
                this.setOrderParam({item_id: item.item_id, param: "status", value: item.status})
              })
          }else {
            response.data.items.forEach((item) => {
              this.setOrderParam({item_id: item.item_id, param: "status", value: item.status})
            })
          }
        let audio = new Audio(require('@/assets/sound/material_ok.wav'));
        audio.play();
        this.$bvModal.hide('edit-order')
        eventBus.$emit('clean-text-input')

      })
      .catch(() => {
        //toast error save
        let audio = new Audio(require('@/assets/sound/material_alert.wav'));
        audio.play();
        this.$bvToast.toast('Ошибка сохранения', {
          title: 'Ошибка',
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-top-center'
        })
      })
    },
    doSaveParts: function (status){

      /// Если количество 1 то и получить мы можем только 1 штуку
      if(parseInt(this.order.qty) === 1){
        this.recv = 1
      }

      if(status === 'tomoscow'){
        return this.doSaveTracks('tomoscow')
      }

      // recv > 0
      if(parseInt(this.recv) < 1){
        this.$bvToast.toast('Нужно указать количство', {
          title: 'Ошибка',
          variant: 'warning',
          solid: true,
          toaster: 'b-toaster-top-center',
        })
      }

      if(parseInt(this.weight) <= 25){
        this.weight = 25
      }

      Http.doPartsReceivedPartial({
        "item_id": parseInt(this.order.item_id),
        "set_manual_weight": this.weight,
        "cancelIncome": this.cancelIncome,
        "status": status,
        "recv": this.recv,
        "barcode_full": this.barcode_full,
        // item_id=176422&set_current_weight=0&set_manual_weight=500
      }).then((response) => {
          response.data.items.forEach((item) => {
            if(item.status) {
              this.setOrderParam({item_id: item.item_id, param: "status", value: item.status})
            }
            if(response.data.weight_set) {
                this.setOrderParam({item_id: item.item_id, param: "weight", value: response.data.weight_set})
                this.setOrderParam({item_id: item.item_id, param: "weight_source", value: 'confirmed'})
            }
            if(item.recv) {
              this.setOrderParam({item_id: item.item_id, param: "recv", value: item.recv})
              this.setOrderParam({item_id: item.item_id, param: "recv_history", value: item.recv_history})
            }

            if(item.can_status ===  "inmoscow"){
              // if(parseInt(item.recv) === 1 || confirm('Все получено, отметить ?')){
              this.doSaveTracks("inmoscow")
              // }
            }

          })

          if(this.cancelIncome){
            this.$store.commit('setOrder', {'order': null});
            eventBus.$emit('clean-text-input')
          }
          let audio = new Audio(require('@/assets/sound/material_ok.wav'));
          audio.play();
          this.$bvModal.hide('edit-order')
          eventBus.$emit('clean-text-input')

      })
      .catch(() => {
        //toast error save
        let audio = new Audio(require('@/assets/sound/material_alert.wav'));
        audio.play();
        this.$bvToast.toast('Ошибка сохранения', {
          title: 'Ошибка',
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-top-center',
        })

      })
    },
    // focusQty: function () {
    //   setTimeout(() => {
    //     this.$refs.input_qty.focus();
    //   }, 150)
    // },
    // focusWeight: function () {
    //   setTimeout(() => {
    //     this.$refs.input_weight.focus();
    //   }, 150)
    // },
    // focusBarcode: function () {
    //   setTimeout(() => {
    //     this.$refs.input_barcode.focus();
    //   }, 150)
    // },
  },
  mounted() {
    eventBus.$on('clean-inputWeight', () => {
      console.log('on clean-inputWeight')
      this.setNextFocus()
    });
    eventBus.$on('clean-inputQty', () => {
      console.log('on clean-inputQty')
      this.setNextFocus()
    })
  },
  beforeDestroy() {
    // removing eventBus listener
    eventBus.$off('clean-inputWeight')
    eventBus.$off('clean-inputQty')
  }
}
</script>

<style scoped>
.table-sm th, .table-sm td {
  padding: 0.15rem;
}
.text_green {
  color: green;
}
.text_red {
  color: red;
}
</style>
