export default [
  { path: '/', component: () => import('@/components/login/ClientLogin'), name: 'login' },
  { path: '/', component: () => import('@/components/login/ClientLogin'), name: 'index' },
  { path: '/invoices', component: () => import('@/components/WhInvoiceList.vue'), name: "invoices" },
  { path: '/parts', component: () => import('@/components/WhParts.vue'), name: "parts" },
  { path: '/labels', component: () => import('@/components/WhLabels.vue'), name: "labels" },
  { path: '/all', component: () => import('@/components/WhAll.vue'), name: "all" },
  { path: '/tracks', component: () => import('@/components/WhTracks.vue'), name: "tracks" },
  { path: '/weight', component: () => import('@/components/WhWeight.vue'), name: "weight" },
  { path: '/summary', component: () => import('@/components/WhSummary.vue'), name: "summary" },
  { path: '/pack', component: () => import('@/components/WhBox.vue'), name: "client_box" },
  { path: '/pack', component: () => import('@/components/WhBoxTracking.vue'), name: "box_tracks" },
  { path: '/settings', component: () => import('@/components/WhSettings.vue'), name: "settings" },
];
