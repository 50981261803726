<template>

  <b-modal
      centered
      :ok-disabled="true"
      id="edit-order-box"
      :no-close-on-backdrop="true"
      :static="true"
      @show="loadOrderInfo"
      @close="cancelOrderX"
  >
    <template v-slot:modal-title><code><h1>{{userCode(parseInt(order.user_id))}}</h1> {{order.make}} {{order.part_number}} ({{order.status}}/{{order.app_status}})</code></template>
    <template v-slot:modal-footer>
      <b-button variant="warning" size="sm" @click="cancelOrder">Отмена</b-button>
      <b-button variant="success" size="sm" @click="saveOrder" v-if="showSave">Сохранить</b-button>
      <b-button variant="primary" size="sm" @click="removeOrder" v-if="order.app_status === 'inwork'">Убрать</b-button>
    </template>
    <div class="d-block">
      <table class="table table-sm table-borderless table-responsive-sm">
        <tbody>
        <tr ><td colspan="2">
          {{order.barcodes}}
        </td></tr>
        <tr>
          <td :class="weightClass">Вес (<span :class="{'text_green': order.weight_source === 'confirmed', 'text_red': order.weight_source !== 'confirmed'}">{{order.weight}}</span>)</td>
          <td>
            <b-input-group class="mt-0">
              <b-form-input type="text" class="form-control" ref="input_weight" v-model="weight"></b-form-input>
            </b-input-group>
          </td>
        </tr>
        <tr>
          <td :class="barcodeClass">Штрихкод</td>
          <td>
            <b-input-group class="mt-0">
              <b-form-input type="text" class="form-control"  ref="input_barcode" v-model="barcode_full"></b-form-input>
            </b-input-group>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

  </b-modal>
</template>

<script>
import { mapMutations } from 'vuex';
import mixins from "@/services/mixins";
import Http from "@/services/Http";
import eventBus from "@/services/eventBus";
export default {
  components: {},
  strict: true,
  data: () => ({
    order: {},
    weight: null,
    barcode_full: null,
  }),
  name: 'EditOrderBoxModals',
  mixins: [mixins],
  computed: {
    showSave() {
      return this.barcode_full !== null || this.weight != null;
    },
    currentUserID() {
      let user = this.currentUser()
      return parseInt(user["id"])
    },
    weightClass() {
      return {
        'table-danger': this.order.noweight === 1 || this.order.weight_source !== 'confirmed',
      }
    },
    barcodeClass() {

      let danger = false;
      if(this.order.barcodes !== undefined && this.order.barcodes.length < 1){
        danger = true;
      }

      return {
        'table-danger': danger,
      }
    }
  },
  watch: {
    weight: function (val) {
      this.$nextTick(() => {
        this.weight = this.checkCommandInValue(val, 'weight')
      })
    },
    barcode_full: function (val) {
      this.$nextTick(() => {
        this.barcode_full = this.checkCommandInValue(val, 'barcode')
      })
    }
  },
  methods: {
    ...mapMutations(["setOrderParam", 'updateBoxOrder']),


    setNextFocus: function () {
      setTimeout(() => {
      let setFocus = this.$refs.input_barcode;
      console.log('setFocus')
      if (this.order.noweight === 1 || this.order.weight_source !== 'confirmed') {
        console.log('setFocus weight')
        setFocus = this.$refs.input_weight
      }

        setFocus.focus()
      }, 200);

    },

    checkCommandInValue: function (val, type) {
      console.log(val)
      // if val undef or empty return null

      if(val === null){
        return
      }

      if (val.endsWith('!') && val.includes(',')) {
        let parts = val.split(',')
        if (parts.length === 3) {

          // проверяем что возмжно это вес
          if (parts[1] === "0000") {
            this.weight = parts[2].replace('!', '')
            if (type !== 'weight') {
              let audio = new Audio(require('@/assets/sound/material_ok.wav'));
              audio.play();
              this.setNextFocus();
              return parts[0];
            }
            let audio = new Audio(require('@/assets/sound/material_ok.wav'));
            audio.play();
            // this.setNextFocus();
            // return this.weight;
            this.saveOrder();
          }

          // Если проверка прошла в поле штрихкода, очищаем его от лишних управляющих символов
          if (type === 'barcode') {
            return parts[0]
          }
        }
      }
      return val
    },
    cancelOrder: function () {
      this.$store.commit('setOrder', {'order': null});
      this.$bvModal.hide('edit-order-box')
      eventBus.$emit('box-tracking-text-input')
    },
    cancelOrderX: function () {
      this.$bvModal.hide('edit-order-box')
      eventBus.$emit('box-tracking-text-input')
    },
    loadOrderInfo: function () {
      this.barcode_full = null
      this.weight = null
      // Отвязываем заказ от реактивности
      this.order = JSON.parse(JSON.stringify(this.$store.getters.getOrder))
      if (parseInt(this.order.qty) === 1) {
        this.recv = 1;
      }
      setTimeout(() => {
        Http.touchOrder(this.order.item_id);
      }, 1000)
      this.setNextFocus();
    },
    userCode: function (user_id) {
      let user = this.$store.getters.getUsers.find(user => user.id === user_id);
      if (user) {
        return user.code;
      }
      return "No Code";
    },
    saveOrder: function () {
      // Нужно сохранить заказ в коробке вставив в него штрихкод, вес , и статус
      if(this.weight !== null){
        this.updateBoxOrder({item_id: this.order.item_id, param: "new_weight", value: this.weight})
      }
      if(this.barcode_full !== null){
        this.updateBoxOrder({item_id: this.order.item_id, param: "new_barcode_full", value: this.barcode_full})
        this.updateBoxOrder({item_id: this.order.item_id, param: "barcodes", value: this.barcode_full})
      }
      this.updateBoxOrder({item_id: this.order.item_id, param: "app_status", value: 'inwork'})
      this.$bvModal.hide('edit-order-box')
      eventBus.$emit('box-tracking-text-input')
    },
    removeOrder: function (){
      this.$store.commit('updateBoxOrder', {item_id: this.order.item_id, param: "app_status", value: null});
      this.$bvModal.hide('edit-order-box')
      eventBus.$emit('box-tracking-text-input')
    },
    doSaveTracks: function () {

    },
    doSaveParts: function () {

    },
  },
  mounted() {
    this.barcode_full = null
  },
  beforeDestroy() {

  }
}
</script>

<style scoped>
.table-sm th, .table-sm td {
  padding: 0.15rem;
}
.text_green {
  color: green;
}
.text_red {
  color: red;
}
</style>
