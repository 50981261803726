import axios from 'axios'
import {auth} from "@/services/auth";
// import router from "@/router";
// import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions';
// import router from "@/router";
// import store from '@/store'

// axios.interceptors.response.use(
//     (response) => {
//       return response;
//     },
//     function(error) {
//       if (error.response.status === 403) {
//         alert("Ошибка авторизации , токен просрочен");
//         // router.push({ name: "login" });
//       }
//       return Promise.reject(error);
//     }
// );

// get API_URL from .env file
  const API_URL = `https://new.partsliner.com/manager`;
// const API_URL = process.env.VUE_APP_API_URL;

axios.interceptors.response.use((response) => {
  auth
  return response
}, async function (error) {
  const originalRequest = error.config;

  if(originalRequest.url===`${API_URL}/user/refresh_token/`)
    return

  if (error.response.status === 403 && !originalRequest._retry) {
    originalRequest._retry = true;
    const resp = await axios.post(`${API_URL}/user/refresh_token/`, {
      "refresh": auth.getRefreshToken(),
    });
    if(resp === undefined) {
      auth.logOut();
      document.location.href="/";
    }
    if(resp.data.token && resp.data.refresh){
      auth.setToken(resp.data.token, resp.data.refresh);
      originalRequest.headers['Authorization'] = 'Bearer ' + resp.data.token;
      return axios(originalRequest);
    }
  } else {
    return Promise.reject(error);
  }
});



// axios.defaults.withCredentials = true

export default {

  getHeaders () {
    const token = auth.getToken();
    return {
      headers: {
        Authorization: `Bearer ${token}` ,
        // 'Content-Type': 'multipart/form-data'
      },
      // withCredentials: true
    }
  },
  getXLSHeaders () {
    const token = auth.getToken();
    return {
      headers: {
        Authorization: `Bearer ${token}` ,
      },
      responseType: 'blob',
      // withCredentials: true
    }
  },
  getHeadersFormData () {
    const token = auth.getToken();
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    }
  },

  get (url) {
    return axios.get(url, this.getHeaders())
  },

  getUserToken(username, password){
     return axios.post(`${API_URL}/user/get_token/`, {
       "username": username,
       "password": password,
     })
  },

  postXLS (url, data) {
    return axios.post(url, data, this.getXLSHeaders())
  },

  post (url, data) {
    return axios.post(url, data, this.getHeaders())
  },

  postFormData (url, data) {
    return axios.post(url, data, this.getHeadersFormData())
  },

  delete (url, data) {
    return axios.delete(url, { headers: this.getHeaders().headers, data  })
  },

  put (url, data) {
    return axios.put(url, data, this.getHeaders())
  },

  auth (user) {
    return axios.post(`${API_URL}/token/`, user)
  },

  refreshToken (token) {
    return axios.post(`${API_URL}/token/refresh/`, { token })
  },

  getInvocesList() {
    return this.get(`${API_URL}/pwhapp/invoicelist/`)
  },

  getAllUser() {
    return this.get(`${API_URL}/clients/loadall/` )
  },
  getUsersWeightList(data) {
    return this.post(`${API_URL}/pwhapp/loaduserweight/`, data )
  },
  getUsersSummary(data) {
    return this.post(`${API_URL}/pwhapp/summary/`, data )
  },
  setUserWeight(data) {
    return this.post(`${API_URL}/pwhapp/setuserweight/`, data )
  },
  getPartsList(data) {
    return this.post(`${API_URL}/pwhapp/loadlist/`, data )
  },
  getAll(data) {
    return this.post(`${API_URL}/pwhapp/loadall/`, data )
  },
  getTracksList(data) {
    // https://partsliner.com/manager/api/tracking/?invoices=2022-11-20&user_id=-1&login=test&pass=test&type=euro
    return this.post(`${API_URL}/pwhapp/tracking/`, data )
  },
  doPrintLabel(data) {
    return this.post(`${API_URL}/pwhapp/printlabel/`, data )
  },
  doPrintBox(data) {
    return this.post(`${API_URL}/pwhapp/printbox/`, data )
  },
  scannlog(data) {
    // {'search': '1234567890'}
    return this.post(`${API_URL}/pwhapp/scannlog/`, data )
  },
  touchOrder(item_id) {
    // {'search': '1234567890'}
    return this.get(`${API_URL}/pwhapp/touch_order/${item_id}/` )
  },
  doPartsReceived(data) {
    return this.post(`${API_URL}/pwhapp/received/`, data )
    // https://partsliner.com/manager/api/received/?item_id=176422&set_current_weight=0&set_manual_weight=500&login=test&pass=test&type=euro
  //   return
  //   https://partsliner.com/manager/api/received/?item_id=176422&status=tomoscow&set_current_weight=0&set_manual_weight=0&login=test&pass=test&type=euro
  },
  doPartsReceivedPartial(data) {
    return this.post(`${API_URL}/pwhapp/partial/`, data )
  },
  doScanLog(data) {
    return this.post(`${API_URL}/pwhapp/scannlog/`, data )
  },
  doSearchInBase(data) {
    // https://partsliner.com/manager/api/tracking/?search=1234567890zz&invoices=2023-03-11&user_id=172&type=euro
    return this.post(`${API_URL}/pwhapp/searchtracking/`, data )
  },
  doReceiveQtyTracking(data){
    //receive_qty_tracking
    return this.post(`${API_URL}/pwhapp/receive_qty_tracking/`, data )
  }
}


