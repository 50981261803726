import { mapMutations } from 'vuex';
import Http from "@/services/Http";
export default {
  data: () =>({
    reload: false,
    modalAutoOpen: true,
  }),
  methods: {
    ...mapMutations(["setCheckedInvoice", "setOrder"]),
    // selectAllRows: function(){
    //   this.$store.getters.getOrders.forEach(order => {
    //     this.setCheckedInvoice(order.item_id)
    //   })
    // },
    checkInvoice: function (date) {
      this.setCheckedInvoice(date)
    },
    deleteOrderByItemId(item_id) {
      this.$store.commit('removeOrderByItemId', {item_id: item_id})
    },
    addOrderToList(order) {
        this.$store.commit('addOrders', {order: order})
    },
    isCheckedItem: function (item_id) {
        // Проверка выбрана ли строка
      return this.$store.getters.getCheckedItems.indexOf(item_id) !== -1
    },
    currentUser: function () {
      return this.$store.getters.getCurrentUser;
    },
    userCode: function(user_id) {
      return this.$store.getters.getUsers.find(user => user.id === user_id).code;
    },
    editOrder: function (order) {
      // Редактирование заказа
      this.setOrder({order: order})
      this.$bvModal.show(`edit-order`)
    },
    setBoxOrderBarcode: function () {

    },
    editOrderBox: function (order) {
      // Редактирование заказа
      this.setOrder({order: order})
      this.$bvModal.show(`edit-order-box`)
    },
    isCheckedInvoice: function (date) {
      return this.$store.getters.getSelectedInvoices.indexOf(date) !== -1
    },
    logLongTrack: function (value, subtype) {
      if (value === undefined || value === null) {
        return
      }
      // Логирование длинного трека , более 9 символов
      Http.doScanLog({"search": value, "subtype": subtype})
        .catch(error => {
          this.$bvToast.toast(
        `Ошибка логирования длинного трека: ${error}`,
              {
              title: `Ошибка`,
              variant: `danger`,
              solid: true
          })
        })
    },
    loadAllList() {
      if(this.$store.state.mainDate === null) {
        this.$router.push({name: 'invoices', replace: true})
        return
      }
      Http.getAll({
        mainDate: this.$store.state.mainDate,
        checkedInvoices: this.$store.state.checkedInvoices,
        type: this.$store.state.checkedType,
      })
      .then((response) => {
        this.setOrders({orders: response.data.items});
      })
      .catch((error) => {
        console.log(error)
      })
    },

  },
  computed: {
    countUsers: function (){
      return this.$store.getters.getUsers.length
    },
  }
}

