import Vue from 'vue'
import App from './App.vue'
import router from "@/router";
import VueRouter from "vue-router";
import Vuex from 'vuex';
import VueAxios from 'vue-axios'
import VueNativeSock from 'vue-native-websocket'
import axios from 'axios'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// import * as Sentry from "@sentry/vue";
// import { BrowserTracing } from "@sentry/tracing";

Vue.config.productionTip = true
Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(VueAxios, axios);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

const store = new Vuex.Store({
  strict: true,
  state: {
    user: null,
    auth: {
      loggedIn: false,
    },
    managerIDS: {
      530: 'ВЗ',
      529: 'АЗ',
      526: 'ДИ',
      537: 'ДД',
      527: 'ГГ',
      528: 'НВ',
      564: 'USR1',
      565: 'USR2',
      566: 'USR3',
      567: 'USR4',
    },
    users: [],
    orders: [],
    order: null,
    invocesSelected: false,
    mainDate: null,
    checkedInvoices: [],
    checkedType: 'euro',
    checkedItems: [],
    socket: {
      isConnected: false,
      message: '',
      reconnectError: false,
    },
    cleanAfterCheck: true,
  },
  mutations: {
    setCleanAfterCheck(state, payload) {
      state.cleanAfterCheck = payload
    },
    setCheckedItems(state, item_id) {
      if(state.checkedItems.indexOf(item_id) === -1){
        state.checkedItems.push(item_id)
      }else{
        state.checkedItems.splice(state.checkedItems.indexOf(item_id),1)
      }
    },
    setOrder(state, payload) {
      state.order = payload.order
    },
    removeOrderByItemId(state, payload) {

      // find order by item_id
      const index = state.orders.findIndex(function (item) {
          return parseInt(item.item_id) === parseInt(payload.item_id)
      })

      state.orders.splice(index,1)

    },
    updateBoxOrder(state, payload) {

      console.log(payload)
      // get new jey timestamp
      const timestamp = new Date().valueOf()

      // find item index by_item_id
      let Index = state.orders.findIndex(function (item) {
        return parseInt(item.item_id) === parseInt(payload.item_id)
      })


      // update
      if(state.orders[Index]) {
        if(payload.param === "barcodes"){
          state.orders[Index][payload.param].push(payload.value)
        }else{
          state.orders[Index][payload.param] = payload.value
        }

        state.orders[Index]["key"] = `${payload.item_id}${timestamp}`
      }

    },
    setCheckedInvoice(state, date) {
      if(state.checkedInvoices.indexOf(date) === -1){
        state.checkedInvoices.push(date)
      }else{
        state.checkedInvoices.splice(state.checkedInvoices.indexOf(date),1)
      }
    },
    setMainDate(state, date) {
        state.mainDate = date
    },
    resetCheckedInvoces(state) {
        state.checkedInvoices = []
    },
    setLoggedIn(state, isLoggedIn){
      state.auth.loggedIn = isLoggedIn;
    },
    setCurrentUser(state, user){
      state.user = user;
    },
    invocesSelected(state, isInvocesSelected){
      state.invocesSelected = isInvocesSelected;
    },
    setCheckedType(state, checkedType){
      state.checkedType = checkedType;
    },
    setUsers(state, payload) {
      state.users = payload.users
    },
    setOrders(state, payload) {
        state.orders = payload.orders
    },
    addOrders(state, payload) {
        state.orders.push(payload.order)
    },
    setOrderParam(state, payload){
      Vue.prototype.$socket.sendObj({
        "type": "param",
        "payload": payload
      })
    },
    // setOrderParams(state, payload) {
    //   if (payload.item_id) {
    //     const timestamp = new Date().valueOf()
    //
    //     let Index = state.orders.findIndex(function (item) {
    //       return parseInt(item.item_id) === parseInt(payload.item_id)
    //     })
    //
    //     if(state.orders[Index]) {
    //       state.orders[Index][payload.param] = payload.value
    //       state.orders[Index]["key"] = `${payload.item_id}${timestamp}`
    //     }
    //   }
    // },
    SOCKET_ONOPEN (state, event)  {
      Vue.prototype.$socket = event.currentTarget
      state.socket.isConnected = true
      setInterval(function (){
        Vue.prototype.$socket.sendObj({"ping!": "pong!"})
      },10000)
    },
    SOCKET_ONCLOSE (state)  {
      state.socket.isConnected = false
    },
    SOCKET_ONERROR (state, event)  {
      // eslint-disable-next-line
      console.error(state, event)
    },
    // default handler called for all methods
    SOCKET_ONMESSAGE (state, message)  {
      state.socket.message = message
      if(message.payload === undefined ) {
        return
      }

      if(message.payload.type === "param") {
        //Обновление чере вебсокет обычных ордеров
        let payload = message.payload.payload
        if (payload.item_id) {
          const timestamp = new Date().valueOf()

          let Index = state.orders.findIndex(function (item) {
            return parseInt(item.item_id) === parseInt(payload.item_id)
          })

          if(state.orders[Index]) {
            state.orders[Index][payload.param] = payload.value
            state.orders[Index]["key"] = `${payload.item_id}${timestamp}`
          }
        }
      }
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT(state, count) {
      // eslint-disable-next-line
      console.info(state, count)
    },
    SOCKET_RECONNECT_ERROR(state) {
      state.socket.reconnectError = true;
    }
  },
  getters: {
    getSocketIsConnected: state => {
      return state.socket.isConnected
    },
    getSelectedInvoices: state => {
      return state.checkedInvoices
    },
    getCheckedItems: state => {
      return state.checkedItems
    },
    getCurrentUser: state => {
      return state.user
    },
    getManagerIDS: state => {
        return state.managerIDS
    },
    getUsers: state => {
      return state.users
    },
    getOrder: state => {
      return state.order
    },
    getOrders: state => {
      return state.orders
    },
    getNetworkStatus: state => {
      return state.socket.isConnected
    }
  },
  actions: {
    sendMessage: function(context, message) {
      Vue.prototype.$socket.send(message)
    }
  }
});


let client_id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
Vue.use(VueNativeSock, `wss://admin.partsliner.com/wsapp/${client_id}`, {
  reconnection: true,
  store: store,
  format: 'json',
  // reconnectionAttempts: 5
  reconnectionDelay: 3000
})

// Sentry.init({
//   Vue,
//   dsn: "https://a80413d36ced49d889963491f9087734@sentry.greyhard.ru/2",
//   integrations: [
//     // new BrowserTracing({
//     //   routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       // tracePropagationTargets: ["localhost", "my-site-url.com", /^\//],
//     // }),
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
